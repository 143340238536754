import { Container, Carousel, EditorialCard, Row, Col, Icon } from '@mch-group/uikit-components'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import React from 'react'
import { MediaContextProvider } from '@responsive'
import styled from 'styled-components'
import CustomLinkTag from '@components/CustomLinkTag'
import { cloudinary } from '@constants'
import useDiscoverMoreData from '../../hooks/useDiscoverMoreData'
import { Splide } from '@splidejs/react-splide'

// Default theme
import '@splidejs/react-splide/css'

// or other themes
// import '@splidejs/react-splide/css/skyblue'
// import '@splidejs/react-splide/css/sea-green'

// or only core styles
import '@splidejs/react-splide/css/core'
import CategoryTitle from './Category'
import { formatArticleLink } from '@utils'
import dayjs from 'dayjs'

const CHDiscoverMore = () => {
  const {
    selectedCategory,
    currentBlockData,
    prevArrowRef,
    nextArrowRef,
    selected,
    labels,
    splideRef,
    dateFEFormat
  } = useDiscoverMoreData()

  return currentBlockData ? (
    <MediaContextProvider>
      <StyledSection className='overflow-hidden'>
        <StyledContainer className='px-5 px-lg-8 px-md-7 pt-12 pt-md-14 mt-0'>
          <div className='text-medium text-light d-flex justify-content-center mb-10'>
            {currentBlockData?.fields.title || 'Discover More'}
          </div>
        </StyledContainer>
        <Container className='px-5 px-lg-8 px-md-7'>
          <Row className='mb-10 mb-md-14'>
            <Col className='position-relative'>
              <SplideStyled
                options={{
                  pagination: false,
                  type: 'loop',
                  focus: 'center',
                  perPage: 3,
                  arrows: false,
                  breakpoints: {
                    1023: {
                      perPage: 1
                    }
                  }
                }}
                onMounted={(splide) => {
                  // Save the Splide instance reference when mounted
                  //@ts-ignore
                  splideRef.current = splide
                }}
                aria-label='My Favorite Images'
              >
                {selectedCategory?.map((category, index) => (
                  <CategoryTitle key={index} selectedCategory={category} />
                ))}
              </SplideStyled>
              {/* Render your custom arrow components */}
              <button ref={prevArrowRef} className='text-white splide__arrow splide__arrow--prev btn-icon'>
                <Icon name='chevron-right' />
              </button>
              <button ref={nextArrowRef} className='splide__arrow splide__arrow--next btn-icon'>
                <Icon name='chevron-right' />
              </button>
            </Col>
          </Row>
        </Container>
        <StyledContainer className='px-5 px-lg-8 px-md-7 pb-12 pb-md-14'>
          <Carousel
            editorialTitle={false}
            showTopBorders={false}
            showBottomBorders={false}
            exceedTrack={true}
            options={{ textLink: true, arrows: 0 } as any}
            controlButtonLabel=''
            //@ts-ignore
            cssStyles={{ paddingTop: 0, paddingBottom: '0!important' }}
          >
            {selectedCategory?.map(
              (_, index) =>
                selected === index &&
                currentBlockData?.fields?.[`storiesWithAPIResults${index}`]?.[0]?.data?.items?.map((story) => (
                  <Carousel.Slide key={story?.id}>
                    <EditorialCard
                      imgLoading='lazy'
                      layout='vertical'
                      size='medium'
                      language={undefined}
                      a11yData={{
                        ariaLabel: story?.title,
                        imageAlt: story?.description
                      }}
                      link={story?.redirectUrl ? story?.redirectUrl : formatArticleLink(story?.url)}
                      urlImage={`${cloudinary.throughCloudfront(
                        `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_400,q_auto,f_auto/${story.imageUrl}`
                      )}`}
                      heading={story?.title}
                      description={story?.description}
                      date={dayjs(story?.publishedDate, 'YYYY-MM-DD').format(dateFEFormat)?.replace('.', '')}
                      textColour='white'
                      ctaLabel={story?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                      linkRenderer={(link, children) => (
                        <CustomLinkTag
                          url={link}
                          data-mch-tracking={true}
                          data-mch-tracking-action-click={44}
                          data-mch-tracking-action-screenprint={40}
                          data-mch-tracking-measure={story?.id}
                          data-mch-tracking-measure2='discovermore'
                        >
                          {children}
                        </CustomLinkTag>
                      )}
                    />
                  </Carousel.Slide>
                ))
            )}
          </Carousel>
        </StyledContainer>
      </StyledSection>
    </MediaContextProvider>
  ) : null
}

const StyledSection = styled.section`
  background-color: #242424;

  .splide__arrow--prev {
    position: absolute;
    background: none;
    opacity: 1;
    left: 3%;

    @media (min-width: 1024px) {
      left: 30%;
    }

    @media (min-width: 1280px) {
      left: 34%;
    }

    svg {
      fill: #fff;
      width: 24px;
      height: 24px;
    }
  }

  .splide__arrow--next {
    position: absolute;
    background: none;
    right: 3%;
    opacity: 1;

    @media (min-width: 1024px) {
      right: 30%;
    }

    @media (min-width: 1280px) {
      right: 34%;
    }

    svg {
      fill: #fff;
      width: 24px;
      height: 24px;
    }
  }
`

const StyledContainer = styled(Container)`
  .slider-container {
    display: flex;
    overflow: hidden;
  }

  .carousel-arrows {
    display: none;
  }

  .transitioning .slider-item {
    transition: transform 0.5s ease;
  }

  .title-container {
    display: none;
  }
`

const SplideStyled = styled(Splide)`
  padding: 0;

  .splide {
    &__slide {
      display: flex;
      justify-content: center;
      align-items: center;

      h4 {
        color: ${tokens.color.light.base.neutrals['700'].value};
      }

      &.is-active {
        h4 {
          color: white;
        }
      }
    }
  }
`

export default CHDiscoverMore
