import CustomLinkTag from '@components/CustomLinkTag'
import { cloudinary, dateFormatForContentHub } from '@constants'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { Media, MediaContextProvider } from '@responsive'

import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import {
  Carousel,
  Col,
  Container,
  EditorialBanner,
  EditorialCard,
  Icon,
  Row,
  TextLink
} from '@mch-group/uikit-components'
import styled from 'styled-components'
import useContenthubContext from '../context'
import { PageContext } from '@providers/PageContext'
import { useContext } from 'react'
import { formatArticleLink } from '@utils'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-tw'

const CHInfocus = () => {
  const {
    page: { siteLanguage }
  } = useContext(PageContext)
  const { state } = useContenthubContext()
  const labels = useLabelsFromAPI(['News'], siteLanguage)
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'In Focus: Fair')
  const fields = currentBlockData?.fields
  dayjs.locale(siteLanguage === 'zh_CN' ? 'zh-tw' : siteLanguage || '')
  const dateFEFormat = dateFormatForContentHub[siteLanguage]

  // if upload media type is selected from cms chub
  // uploadMediaType === 0 - images from the story
  // uploadMediaType === 1 - upload custom media

  const bgDesktopImgUrl =
    fields?.uploadMediaType === 0 ? fields?.selectedDesktopStoryThumbnail0 : fields?.uploadMediaUrlDesktop0

  const bgMobileImgUrl =
    fields?.uploadMediaType === 0 ? fields?.selectedMobileStoryThumbnail0 : fields?.uploadMediaUrlMobile0
  const storiesWithAPIResults = fields?.storiesWithAPIResults?.[0].data?.items

  return currentBlockData ? (
    <MediaContextProvider>
      <div className='bg-black overflow-hidden' id='infocus'>
        <EditorialBanner
          mediaType='image'
          urlImage={`${cloudinary.throughCloudfront(
            `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_2000,q_auto,f_auto/${fields?.imageUrl}`
          )}`}
          // should be mandatory only when variant is display
          heading={fields?.title} // always mandatory
          description={fields?.shortTitle} // mandatory for display and text, not for video
          ctaLabel={fields?.ctaLabel || 'DIVE IN'} // always mandatory
          link={fields?.ctaURL}
          linkRenderer={(link, children, linkTitle, ariaLabel) => (
            <CustomLinkTag
              url={link}
              ariaLabel={ariaLabel}
              title={linkTitle}
              data-mch-tracking={true}
              data-mch-tracking-action-click={45}
              data-mch-tracking-action-screenprint={46}
              data-mch-tracking-measure='infocus'
            >
              {children}
            </CustomLinkTag>
          )}
          headingStyle='display'
          ctaAriaLabel={fields?.ctaLabel}
        />
        {storiesWithAPIResults?.length > 0 && (
          <StyledContainer className='px-5 px-lg-8 px-md-7 py-11 py-lg-12 py-xl-14'>
            <Carousel
              editorialTitle={false}
              showTopBorders={false}
              showBottomBorders={false}
              exceedTrack={true}
              options={{ textLink: true, arrows: 0 } as any}
              controlButtonLabel='View all'
              //@ts-ignore
              cssStyles={{ marginTop: 0, paddingTop: '0', paddingBottom: '0 !important' }}
            >
              {storiesWithAPIResults.map((story) => (
                <Carousel.Slide key={story.id}>
                  <EditorialCard
                    imgLoading='lazy'
                    layout='vertical'
                    size='medium'
                    language={undefined}
                    a11yData={{
                      ariaLabel: story.title,
                      imageAlt: story.description
                    }}
                    link={story?.redirectUrl ? story?.redirectUrl : formatArticleLink(story.url)}
                    urlImage={`${cloudinary.throughCloudfront(
                      `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_400,q_auto,f_auto/${story.imageUrl}`
                    )}`}
                    heading={story.title}
                    description={story.description}
                    date={dayjs(story?.publishedDate, 'YYYY-MM-DD').format(dateFEFormat)?.replace('.', '')}
                    textColour='white'
                    ctaLabel={story.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                    linkRenderer={(link, children) => (
                      <CustomLinkTag
                        url={link}
                        data-mch-tracking={true}
                        data-mch-tracking-action-click={44}
                        data-mch-tracking-action-screenprint={40}
                        data-mch-tracking-measure={story?.id}
                        data-mch-tracking-measure2='infocus'
                      >
                        {children}
                      </CustomLinkTag>
                    )}
                  />
                </Carousel.Slide>
              ))}
            </Carousel>
          </StyledContainer>
        )}
        <Container className='px-5 ps-md-8 pe-md-0 mb-11'>
          <CustomLinkTagStyled
            url={
              fields?.APIResults[0]?.redirectUrl
                ? fields?.APIResults[0]?.redirectUrl
                : formatArticleLink(fields?.APIResults[0]?.url)
            }
            data-mch-tracking={true}
            data-mch-tracking-action-click={44}
            data-mch-tracking-action-screenprint={40}
            data-mch-tracking-measure={fields?.APIResults[0]?.id}
            data-mch-tracking-measure2='infocus-closure'
          >
            <Row>
              <Col md={6} lg={5} xl={4} className='d-flex flex-column mt-0'>
                <div>
                  <h3 className='font-color-light-base-0 editorial-heading-h3'>{fields?.APIResults[0]?.title}</h3>
                  <div className='font-color-light-base-500 mt-4 text-label-small'>
                    {fields?.APIResults[0]?.authorName || ''} |{' '}
                    {dayjs(fields?.APIResults[0]?.publishedDate, 'DD/MM/YYYY HH:mm')
                      .format(dateFEFormat)
                      ?.replace('.', '')}
                  </div>
                  <Media greaterThanOrEqual='md'>
                    <p className='font-color-light-base-500 d-none d-sm-block mt-5 mt-xl-7 text-medium mb-0'>
                      {fields?.APIResults[0]?.description}
                    </p>
                  </Media>
                </div>
                <div className='mt-5 mb-7 mb-lg-12 mb-xl-14'>
                  <TextLink useLink={true} className='text-white'>
                    {labels?.labels?.News?.ReadMoreCTA}
                  </TextLink>
                  <Icon name='arrow-right' color='var(--bs-mch-white)' />
                </div>
              </Col>
              <Col md={6} lg={6} xl={6} className='offset-lg-1 offset-xl-2 mt-0  pe-md-0 pe-xxl-10 ps-xxl-0'>
                <Media greaterThanOrEqual='md'>
                  <div className='desktop-img-container'>
                    <img
                      loading='lazy'
                      className='w-100 h-100'
                      alt={fields?.APIResults[0]?.alt}
                      src={`${cloudinary.throughCloudfront(
                        `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_1440,q_auto,f_auto/${bgDesktopImgUrl}`
                      )}`}
                    />
                  </div>
                </Media>
                <Media lessThan='md'>
                  <div className='mobile-img-container'>
                    <img
                      loading='lazy'
                      className='w-100 h-100'
                      alt={fields?.APIResults[0]?.alt}
                      src={`${cloudinary.throughCloudfront(
                        `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_1440,q_auto,f_auto/${bgMobileImgUrl}`
                      )}`}
                    />
                  </div>
                </Media>
              </Col>
            </Row>
          </CustomLinkTagStyled>
        </Container>
      </div>
    </MediaContextProvider>
  ) : null
}

const StyledContainer = styled(Container)`
  .splide {
    padding-top: 0;
  }

  .title-container {
    display: none;
  }
`

const CustomLinkTagStyled = styled(CustomLinkTag)`
  &:hover {
    h3,
    .btn-link,
    button {
      text-decoration: underline;
    }
  }

  button {
    color: ${tokens.color.light.base.neutrals[0].value};

    &:hover {
      color: ${tokens.color.light.base.neutrals[0].value};
    }
  }

  svg {
    &:hover {
      fill: ${tokens.color.light.base.neutrals[0].value} !important;
    }
  }

  .mobile-img-container {
    height: 360px;

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  .desktop-img-container {
    @media (min-width: ${global.uikitVars.breakpointMd}) and (max-width: ${global.uikitVars.breakpointMdMax}) {
      height: 400px;
    }

    @media (min-width: ${global.uikitVars.breakpointLg}) {
      height: 500px;
    }

    @media (min-width: ${global.uikitVars.breakpointXxl}) {
      height: 540px;
    }

    img {
      object-fit: cover;
    }
  }
`

export default CHInfocus
